<template>
  <div>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row :gutter="20">
        <el-col :span="3">
          <el-input v-model="search.clothesName" placeholder="礼服名" size="medium" clearable></el-input>
        </el-col>
        <el-col :span="3">
          <el-date-picker
              style="width: 100%"
              size="medium"
              v-model="search.applicationDate"
              type="date"
              clearable
              placeholder="申请时间">
          </el-date-picker>
        </el-col>
        <el-col :span="3">
          <el-input v-model="search.cusName" placeholder="客户姓名" size="medium" clearable></el-input>
        </el-col>
        <el-col :span="3">
          <el-select v-model="search.state" size="medium" placeholder="申请状态" clearable>
            <el-option
                v-for="item in stateArray"
                :key="item.state"
                :label="item.state"
                :value="item.state">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-input v-model="search.reviewerId" placeholder="审核人" size="medium" clearable></el-input>
        </el-col>
        <el-col :span="3">
          <el-select v-model="search.purpose" size="medium" placeholder="调货目的" clearable>
            <el-option
                v-for="item in purposeArray"
                :key="item.purpose"
                :label="item.purpose"
                :value="item.purpose">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 15px">
        <el-col :span="3">
          <el-select v-model="search.shopId" size="medium" placeholder="礼服所在店铺" clearable>
            <el-option
                v-for="item in shopArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="search.targetShopId" size="medium" placeholder="调货目标店铺" clearable>
            <el-option
                v-for="item in shopArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-date-picker
              style="width: 100%"
              size="medium"
              v-model="search.dateStart"
              type="date"
              clearable
              placeholder="周期开始时间">
          </el-date-picker>
        </el-col>
        <el-col :span="3">
          <el-date-picker
              style="width: 100%"
              size="medium"
              v-model="search.dateEnd"
              type="date"
              clearable
              placeholder="周期结束日期">
          </el-date-picker>
        </el-col>
        <el-col :span="3">
          <el-input v-model="search.expressNumber" placeholder="快递单号" size="medium" clearable></el-input>
        </el-col>
        <el-col :span="3">
          <el-input v-model="search.senderId" placeholder="寄出人" size="medium" clearable></el-input>
        </el-col>
        <el-col :span="5">
          <el-button size="medium" type="primary" @click="doSearch">搜索</el-button>
          <el-button size="medium" type="warning" @click="reset">重置</el-button>
        </el-col>
      </el-row>
    </fieldset>
    <el-row style="margin-top: 15px;padding-left: 10px">
      <el-table
          :data="applicationList"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '2px 0'}"
          style="width: 99%"
          v-loading="loading"
          element-loading-text="拼命加载中"
          max-height="635"
          border>
        <el-table-column sortable prop="applicationDate" label="申请时间" align="center" width="120" fixed :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="clothesName" label="礼服名" align="center" width="150" fixed :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="applicationEmpName" label="申请人" align="center" width="100" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="shopName" label="礼服所在店铺" align="center" width="120" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="targetShopName" label="调货目标店铺" align="center" width="120" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="purpose" label="调货目的" align="center" width="120" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="remark" label="调货描述" align="center" width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="dateStart" label="周期起始日期" align="center" width="120" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="dateEnd" label="周期结束日期" align="center" width="120" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="cusName" label="客户名" align="center" width="80" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="reviewerName" label="审核人" align="center" width="80" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="expressNumber" label="快递单号" align="center" width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="senderName" label="寄出人" align="center" width="120" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="rejectRemark" label="拒绝原因" align="center" width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="state" label="状态" align="center" width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="操作" align="center" fixed="right" v-slot="scope"  width="300">
          <el-button type="primary" size="mini" v-if="ClothesOperationState.send(scope.row.state)" @click="send(scope.row)">执行</el-button>
          <el-button type="danger" size="mini" v-if="ClothesOperationState.cancel(scope.row.state)" @click="cancel(scope.row)">取消</el-button>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 3px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="100"
          layout="prev, pager, next,total, jumper, sizes"
          :total="total">
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
import {STATE} from "@/constant/ClothesOperationConst.js"
export default {
  name: "operation-list",
  created() {

    this.pageInit()
    console.log()
  },
  data() {
    return {
      search: {
        clothesName: "",
        applicationDate: null,
        cusName: "",
        state: "",
        reviewerName: null,
        purpose: null,
        shopId: null,
        targetShopId: null,
        dateStart: null,
        dateEnd: null,
        expressNumber: null,
        senderName: null,
        tenantCrop: localStorage.getItem("tenantCrop"),
        applicationEmpId: JSON.parse(localStorage.getItem("emp")).id,
      },
      stateArray: [
        {state: "未提交"},{state: "已提交未审核"},
        {state: "已审核未寄出"},{state: "已寄出"},
        {state: "已拒绝"},
      ],
      purposeArray: [
        {purpose: "出件"},{purpose: "预约锁定款式"},
        {purpose: "归还"},{purpose: "分店清货"},
        {purpose: "其他"}
      ],
      empId: JSON.parse(localStorage.getItem("emp")).id,
      shopArray: [],
      total: 0,
      page: 1,
      limit: 100,
      applicationList: [],
      loading: false,
      ClothesOperationState:new STATE(),
    }
  },
  methods: {
    reset() {
      this.search.clothesName = ""
      this.search.applicationDate = null
      this.search.cusName = ""
      this.search.state = ""
      this.search.reviewerName = null
      this.search.purpose = null
      this.search.shopId = null
      this.search.targetShopId = null
      this.search.dateStart = null
      this.search.dateEnd = null
      this.search.expressNumber = null
      this.search.senderName = null
    },
    pageInit() {
      this.$selectUtils.shopIdsIsValid().then(response => {
        this.shopArray = JSON.parse(response.data.data)
      })
      this.queryClothesOperationApplicationList()
    },
    queryClothesOperationApplicationList() {
      this.loading = true
      this.$axios({
        method: "get",
        url: "/clothesOperationApplication/queryClothesOperationApplicationList",
        params: {
          page: this.page,
          limit: this.limit,
          ...this.search,
        }
      }).then(response => {
        this.loading = false
        this.applicationList = response.data.data.list
        this.total = response.data.data.total
      })
    },
    handleSizeChange(val) {
      this.limit = val
      this.queryClothesOperationApplicationList();
    },
    handleCurrentChange(val) {
      this.page =val
      this.queryClothesOperationApplicationList()
    },
    doSearch() {
      this.page = 1
      this.queryClothesOperationApplicationList()
    },
    cancel(val) {
      this.$confirm('确定要取消 ' + val.clothesName + ' 的这条调货申请吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        this.$axios({
          method: 'delete',
          url: "/clothesOperationApplication/cancel",
          data: {
            id: val.id
          }
        }).then(response => {
          if (response.data.code === 200) {
            this.$message.success("取消成功")
            this.queryClothesOperationApplicationList()
          } else {
            this.$message.error(response.data.msg)
          }
        })
      }).catch(() => {
      })

    },
    send(val) {
      this.$prompt('请填写快递单号', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        inputPattern: /^[a-zA-Z0-9]+$/,
        inputErrorMessage: '轻输入快递单号'
        }).then(({ value }) => {
          this.$axios({
          method: 'delete',
          url: "/clothesOperationApplication/send",
          data: {
            id: val.id,
            senderId:this.empId,
            expressNumber:value
          }
        }).then(response => {
          if (response.data.code === 200) {
            this.$message.success("取消成功")
            this.queryClothesOperationApplicationList()
          } else {
            this.$message.error(response.data.msg)
          }
        })
        }).catch(() => {

        });

    },
  },
}
</script>

<style scoped>

</style>